import React, { Component } from "react";
import OrderSummary from "./OrderSummary";
import { db } from "./Firebase/firebase";
import config from "../config";

export default class Current extends Component {
  state = {
    orders: [],
    play: false,
  };
  componentDidMount() {
    db.collection("Orders")
      .orderBy("createdAt", "desc")
      .where("outlet", "==", config.outlet)
      .onSnapshot((querySnapShot) => {
        let orders = [];
        this.props.playNotif();
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          orders.push({ ...itemData, id: item.id });
        });
        this.setState(
          {
            orders: orders,
          },
          () => {
            console.log(this.state.orders);
          }
        );
      });
  }
  render() {
    let newOrders = this.state.orders.filter((order) => {
      return order.status !== "Completed";
    });
    return (
      <div id="current">
        {newOrders.map((order) => {
          return <OrderSummary data={order}></OrderSummary>;
        })}
      </div>
    );
  }
}
