import React, { Component } from "react";
import { db } from "./Firebase/firebase";
import config from "../config";
import moment from "moment";
export default class OrderSummary extends Component {
  state = {
    status: "",
  };
  calcAmount = (cartData) => {
    let newData = [...cartData];
    newData = newData.map(item => {
      item.totalPrice = item.price;
      if (item.options) {
        item.options.map(option => {
          item.totalPrice += option.topup;
        })
      }
      return item;
    })
    let priceSum = newData.map((data) => {
      return data.totalPrice * data.qty;
    });
    if (priceSum.length !== 0) {
      let calcAmt = priceSum.reduce((acc, cur) => {
        return acc + cur;
      });

      return calcAmt;
    } else {
      return 0;
    }
  };

  calcTotalPrice = (item) => {
    let totalPrice = item.price;
    if (item.options) {
      item.options.map(option => {
        totalPrice = totalPrice + option.topup;
      })
    }
    return totalPrice;
  }

  updateStatus = () => {
    db.collection("Orders").doc(this.props.data.id).update({
      status: this.state.status,
    });
  };

  resendOrderEmail = () => {
    let {
      basket,
      name,
      email,
      mode,
      outlet,
      deliveryFee,
    } = this.props.data;

    fetch(
      "https://us-central1-ordering-eaa87.cloudfunctions.net/widgets/send-order-email",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cart: basket, name, email, mode, outlet, deliveryFee }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "succeeded") {
          console.log("email sent")
        } else {
          console.log("Something went wrong. Please try again later.");
        }
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  /*
   {!this.props.receipt ? (
                      <EditCart item={item}></EditCart>
                    ) : null}*/
  render() {
    let {
      basket,
      name,
      email,
      mobile,
      mode,
      status,
      address,
      collectTime,
      createdAt,
      deliveryFee,
      withDiscount,
      withNewDiscount,
      orderDateTime,
      timeString,
      id,
    } = this.props.data;
    if (deliveryFee === undefined) {
      deliveryFee = 0;
    }
    let discount = 1;
    if (withDiscount || withNewDiscount) {
      if (config.outlet === "CM-PB") {
        discount = 0.8;
      } else if (mode === "takeout") {
        discount = 0.9;
      } else {
        discount = 1;
      }
    } else if (withNewDiscount) {
      discount = 1;
    }

    let orderTime = moment(createdAt).format("h:mm a");
    let orderDate = moment(createdAt).format("DD/M/YY");
    let collectDate = timeString ? moment(orderDateTime).format("DD/M/YY") : "";
    return (
      <div className="order-sum">
        {id}
        <button onClick={this.resendOrderEmail}>Send Email</button>
        <div>
          <div>
            <span>Date: </span>
            <span className="date-time">{orderDate}</span>
          </div>
          <div>
            <span>Time: </span>
            <span className="date-time">{orderTime}</span>
          </div>
        </div>
        {basket.map((item, index) => {
          return (
            <div className="order-item" key={item.id + index}>
              <div className="item-details">
                <span className="item-name">
                  {item.name} {item.wineCollection}
                </span>
                <span className="item-price">
                  ${(this.calcTotalPrice(item) * item.qty).toFixed(2)}
                </span>
              </div>
              {item.options ? item.options.length !== 0
                ? item.options.map((item) => {
                  return (
                    <React.Fragment>
                      <br></br>
                      <i>{`${item.label}:${item.value}`}</i>
                    </React.Fragment>
                  );
                })
                : null : null}
              {item.choices
                ? item.choices.map((choiceItem) => {
                  return (
                    <div className="item-qty">
                      <h5>
                        {choiceItem.choice} X{choiceItem.qty}
                      </h5>
                    </div>
                  );
                })
                : null}
              <div className="item-qty">
                <h5>X{item.qty}</h5>
              </div>
              {item.edit ? (
                <span style={{ fontStyle: "italic" }}>{item.edit}</span>
              ) : null}
            </div>
          );
        })}

        <div className="order-total">
          <div className="sub-total">
            <span>subtotal</span>
            <span className="sub-total-amt">
              ${basket !== undefined ? this.calcAmount(basket).toFixed(2) : 0}
            </span>
          </div>
          {withDiscount || withNewDiscount ? (
            <div className="sub-total">
              <span>
                After Discount
              </span>
              <span className="sub-total-amt">
                $
                {basket !== undefined
                  ? (this.calcAmount(basket) * discount).toFixed(2)
                  : 0}
              </span>
            </div>
          ) : null}

          <div className="sub-total">
            <span>delivery</span>
            <span className="sub-total-amt">
              ${basket !== undefined ? deliveryFee.toFixed(2) : 0}
            </span>
          </div>
          <div className="sub-total">
            <span>GST</span>
            <span className="sub-total-amt">
              $
              {basket !== undefined
                ? (
                  (this.calcAmount(basket) * discount + deliveryFee) *
                  0.07
                ).toFixed(2)
                : 0}
            </span>
          </div>
          <div className="total">
            <span>total</span>
            <span className="total-amt">
              $
              {basket !== undefined
                ? (
                  (this.calcAmount(basket) * discount + deliveryFee) *
                  1.07
                ).toFixed(2)
                : 0}
            </span>
          </div>
          <hr></hr>
          <div className="sub-total">
            <span>name</span>
            <span className="sub-total-amt">{name}</span>
          </div>
          <div className="sub-total">
            <span>email</span>
            <span className="sub-total-amt">{email}</span>
          </div>
          <div className="sub-total">
            <span>mobile</span>
            <span className="sub-total-amt">{mobile}</span>
          </div>
          <hr></hr>
          <div className="sub-total">
            <span>service</span>
            <span className="sub-total-amt">{mode}</span>
          </div>
          <div className="sub-total address">
            <span className="sub-total-amt">{address}</span>
          </div>

          <div className="sub-total">
            <span>{mode === "delivery" ? "delivery" : "collection"} time</span>
            <span className="sub-total-amt">{`${collectDate} ${collectTime}`}</span>
          </div>

          <div className="sub-total">
            <span>status</span>
            <span className="sub-total-amt">{status}</span>
          </div>
          <hr></hr>
          <div className="sub-total">
            <select name="status" id="status" onChange={this.onChange}>
              <option value="Payment Confirmed">Payment Confirmed</option>
              <option value="Being Prepared">Being Prepared</option>
              <option value="Ready To Collect">Ready To Collect</option>
              <option value="Being Delivered">Being Delivered</option>
              <option value="Completed">Completed</option>
            </select>
            <button onClick={this.updateStatus}>update</button>
          </div>
        </div>
      </div>
    );
  }
}
