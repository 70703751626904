import React, { Component } from "react";
import OrderSummary from "./OrderSummary";
import { db } from "./Firebase/firebase";
import config from "../config";
export default class Current extends Component {
  state = {
    orders: [],
  };
  componentDidMount() {
    db.collection("Orders")
      .orderBy("createdAt", "desc")
      .where("outlet", "==", config.outlet)
      .where("status", "==", "Completed")
      .onSnapshot((querySnapShot) => {
        let orders = [];
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          orders.push({ ...itemData, id: item.id });
        });
        this.setState(
          {
            orders: orders,
          },
          () => {
            console.log(this.state.orders);
          }
        );
      });
  }

  render() {
    return (
      <div id="archived">
        {this.state.orders.map((order) => {
          return <OrderSummary data={order}></OrderSummary>;
        })}
      </div>
    );
  }
}
