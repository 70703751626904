import React, { Component } from "react";
import { db } from "./Firebase/firebase";
import { Route } from "react-router-dom";
import Current from "./Current";
import Archived from "./Archived";
import NavBar from "./NavBar";

export default class Main extends Component {
  constructor(props) {
    super();
    this.soundRef = React.createRef();
  }
  audio = new Audio(require("../audio/birdroid.ogg"));

  componentDidMount() {
    this.soundRef.current.addEventListener('click', this.initSound);
  }
  componentWillUnmount() {
    this.soundRef.current.removeEventListener('click', this.initSound);
  }
  
  initSound = () => {
    
    this.audio.src = require("../audio/birdroid.ogg");
    this.audio.play();
  };
  playNotif = () => {
    this.audio.play();
  };
  render() {
    return (
      <div id="mains">
        <NavBar></NavBar>
        <button ref={this.soundRef}>Enable Sound</button>
        <Route
          path="/main/archived"
          render={(props) => <Archived {...props}></Archived>}
        ></Route>
        <Route
          path="/main/current"
          render={(props) => (
            <Current {...props} playNotif={this.playNotif}></Current>
          )}
        ></Route>
      </div>
    );
  }
}
