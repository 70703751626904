import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <div className="nav-bar">
      <NavLink to="/main/current">Current</NavLink>
      <NavLink to="/main/archived">Archived</NavLink>
    </div>
  );
};

export default NavBar;
